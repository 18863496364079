@font-face {
  font-family: 'CasperVN';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'CasperVN';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-ThinItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'CasperVN';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-UltraLight.ttf') format('truetype');
}
@font-face {
  font-family: 'CasperVN';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-UltraLightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'CasperVN';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-Normal.ttf') format('truetype');
}
@font-face {
  font-family: 'CasperVN';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-NormalItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'CasperVN';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'CasperVN';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-RegularItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'CasperVN';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'CasperVN';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'CasperVN';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'CasperVN';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'CasperVN';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'CasperVN';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'CasperVN';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'CasperVN';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-BlackItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'CasperVN';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-Heavy.ttf') format('truetype');
}
@font-face {
  font-family: 'CasperVN';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('/assets/fonts/CasperVN-HeavyItalic.ttf') format('truetype');
}
