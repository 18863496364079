@import './fonts.css';
@tailwind base;
@import 'react-datepicker/dist/react-datepicker';

/*
a {
  @apply text-blue-700;
}
a:hover {
  @apply border-b-2 border-blue-700;
} */
@tailwind components;

@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
:root {
  --primary-color: #ff424e;
  --text-dark: #303538;
  --font-base: 14px;
  --toastify-font-family: 'CasperVN' !important;
  --toastify-toast-min-height: 30px !important;
  --toastify-color-error: #ff424e !important;
  --toastify-toast-width: 400px;
}
.Toastify {
  font-size: 13px !important;
}
.postDetail ul,
ol {
  list-style: revert;
  padding-left: 1.5rem;
}

.postDetail tr,
td {
  border-width: revert !important;
  border-color: revert !important;
}

.rc-menu-submenu-active,
.rc-menu-submenu-title {
  padding: 0 !important;
  background-color: unset !important;
}
.rc-menu-submenu-arrow {
  display: none !important;
}

.rc-menu-vertical {
  padding: 0 !important;
}
.rc-menu-item-active {
  background: unset !important;
}

.top-214 {
  top: 214px !important;
}
.top-225 {
  top: 225px !important;
}
.top-67 {
  top: 67px !important;
}
.top-158 {
  top: 158px !important;
}

.yarl__slide_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
input,
textarea {
  outline: none;
}
.yarl__thumbnails_thumbnail {
  background-color: white !important;
  border: 1px solid #dcdcdc !important;
}

:root {
  --primary-color: #ff424e;
  --text-dark: #303538;
  --font-base: 16px;
}

html {
  font-family: 'CasperVN';
}
.clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* react datepicker style */
.react-datepicker {
  @apply relative z-[1] mx-auto my-0 w-[300px] rounded border !border-dark;
}

.react-datepicker__month-container {
  @apply relative w-full px-[10px] py-[15px];
}

.react-datepicker__header {
  @apply !border-b-0 !bg-transparent !p-0;
}

.react-datepicker__current-month {
  @apply mb-[10px];
}
.react-datepicker__year-select,
.react-datepicker__month-select {
  @apply focus:outline-none;
}
.react-datepicker__day-names {
  @apply flex w-full items-center justify-center;
}

.react-datepicker__day-name {
  @apply m-0 h-[40px] w-[60px] font-semibold text-dark-400;
}
.react-datepicker__week {
  @apply flex  w-full items-center justify-center;
}
.react-datepicker__month {
  @apply !m-0 flex flex-col;
}

.react-datepicker__day--outside-month {
  @apply text-dark-400;
}

.react-datepicker__day {
  @apply !m-0 flex h-[40px]  w-[60px] items-center justify-center font-semibold;
}

.react-datepicker__day--selected {
  @apply relative flex h-[40px]  w-[60px] items-center justify-center border !bg-blue-400 text-white;
}

.react-datepicker__day--keyboard-selected {
  @apply relative  flex h-[40px] w-[60px] items-center justify-center border !bg-dark;
}

.react-datepicker__navigation--next {
  @apply absolute !right-[calc(50%_-_100px)] !top-[15px] !important;
}

.react-datepicker__navigation--previous {
  @apply absolute !left-[calc(50%_-_100px)] !top-[15px] !important;
}

.react-datepicker__navigation-icon::before {
  border-width: 1px 1px 0 0 !important;
  border-color: #111 !important;
}

.react-datepicker__day--disabled {
  cursor: not-allowed !important;
  @apply !text-dark;
}

/* CSS slick */
.slick-active,
.slick-current {
  @apply z-10;
}
.slick-arrow {
  text-align: center;
  justify-content: center;
}
.slick-arrow:before {
  content: unset !important;
}
/* total width */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bbbbbb;
  -webkit-box-shadow: 0 0 1px #bbbbbb;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  border-radius: 10px;
}

.scrollbar-track-transparent::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
/* End CSS slick */

/* CSS light box */

.yarl__button {
  @apply !filter-none hover:scale-110;
}
.yarl__navigation_next,
.yarl__navigation_prev {
  @apply hover:translate-y-[-50%];
}

.DropdownMenuContent {
  min-height: 206px;
  min-width: 233px;
  left: -35px;
  position: relative;
  padding: 18px 20px;
  list-style-type: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transform-origin: var(--radix-dropdown-menu-content-transform-origin);
  animation: scaleIn 0.1s ease-out;
}
.DropdownLanguageContent {
  min-height: 40px;
  min-width: 176px;
  position: relative;
  padding: 0;
  list-style-type: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 5px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  animation: scaleIn 0.1s ease-out;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.arrowSlickCs {
  position: absolute;
  z-index: 9;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.slickPrev {
  left: 0;
  right: auto;
}
.slickNext {
  right: 0;
  left: auto;
}
.slick-slide {
  font-size: 0;
}
.flashSaleSlider.flashSale,
.bestSeller.bestSellerSlider,
.trending.trendingSlider,
.productCardSlider {
  /* padding: 0 10px; */
}
.rc-menu {
  border-radius: 8px !important;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15) !important;
  border: none !important;
  /* overflow: hidden; */
}
.non-slick .slick-track {
  margin-left: 0;
}
.productGalleryThumb {
  @apply relative flex w-full items-center justify-center !important;
}
.productGalleryThumb .slick-list {
  flex: 0 0 calc(100% - 54px);
  max-width: calc(100% - 54px);
  @apply relative;
}
.productGalleryThumb .slick-slide {
  @apply relative;
}
.productGalleryThumb .slick-slide > div {
  @apply m-[4px] overflow-hidden rounded-sm border border-dark-100;
}

.productGalleryThumb .slick-current > div {
  @apply border-blue;
}

.form-check-default .form-checkbox-input {
  position: absolute;
  left: -9999px;
}
.form-check-default .form-checkbox-input:focus {
  box-shadow: none;
}
.form-check-default .form-checkbox-input:not(:checked) + label:after {
  color: #000;
  opacity: 0;
}
.form-check-default .form-checkbox-input:checked + label {
  color: #000;
}
.form-check-default .form-checkbox-input:checked + label:after {
  opacity: 1;
}
.form-check-default .form-checkbox-input:disabled + label {
  color: #949494;
}
.form-check-default .form-checkbox-input:disabled + label:before {
  border: 1px solid #949494;
}
.form-check-default .form-checkbox-input:disabled:checked + label {
  color: #949494;
}
.form-check-default .form-checkbox-input:disabled:checked + label:before {
  border: 1px solid #d4d4d4;
  background-color: #f5f5f5;
  background-image: url('/assets/icons/check-dark.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
  color: #949494;
}
.form-check-default .form-checkbox-input:disabled + label:before {
  border: 1px solid #d4d4d4;
  background-color: #d4d4d4;
  color: #d4d4d4;
}
.form-check-default .form-checkbox-label {
  position: relative;
  padding-left: 32px;
  display: flex;
  user-select: none;
}
.form-check-default.bundle .form-checkbox-label {
  padding-left: 10px;
}
.form-check-default .form-checkbox-input:not(:checked) + label:after {
  color: #373737;
  opacity: 0;
}
.form-check-default .form-checkbox-label:before {
  content: '';
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  background: #fff;
  position: absolute;
  top: 2px;
  z-index: 1;
}
.form-check-default .form-checkbox-input:checked + label:before {
  border: 1px solid #00c1d5;
  background-color: #00c1d5;
  background-image: url('/assets/icons/check.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
}

.main-description img {
  @apply !mb-2 !mt-1 !h-auto !w-full;
}
.main-description table {
  @apply !mx-0 !my-1 !h-auto !w-full;
}
.main-description ol {
  @apply !ml-3;
}
.main-description p {
  @apply !mb-2;
}
.main-description li {
  @apply !mb-1;
}
.main-description a,
.postDetail a {
  @apply text-blue;
}
.form-check-default .form-radio-input {
  position: absolute;
  left: -9999px;
}
.form-check-default .form-radio-input:focus {
  box-shadow: none;
}
.form-check-default .form-radio-input:not(:checked) + label:after {
  color: #000;
  opacity: 0;
}
.form-check-default .form-radio-input:checked + label {
  color: #000;
}
.form-check-default .form-radio-input:checked + label:after {
  opacity: 1;
}
.form-check-default .form-radio-input:disabled + label {
  color: #777;
}
.form-check-default .form-radio-input:disabled + label:before {
  border: 1px solid #000;
}
.form-check-default .form-radio-input:disabled:checked + label {
  color: #777;
}
.form-check-default .form-radio-input:disabled:checked + label:before {
  border: 1px solid #000;
  background: transparent;
  color: #777;
}
.form-check-default .form-radio-label {
  position: relative;
  padding-left: 32px;
  display: flex;
  user-select: none;
}
.form-check-default .form-radio-input:not(:checked) + label:after {
  color: #373737;
  opacity: 0;
}
.form-check-default .form-radio-label:before {
  content: '';
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  border-radius: 100%;
  background: #fff;
  position: absolute;
  top: 6px;
}
.form-check-large .form-radio-label:before {
  content: '';
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #d4d4d4;
  border-radius: 100%;
  background: #fff;
  position: absolute;
  top: 2px;
}
.form-check-default .form-radio-label.middle:before {
  content: '';
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #707070;
  border-radius: 100%;
  background: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.form-check-default .form-radio-input:checked + label:before {
  border: 1px solid #00c1d5;
}
.form-check-default .form-radio-input:checked + label:after {
  background-color: #00c1d5;
  left: 0;
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: absolute;
  top: 6px;
  left: 4px;
}
.form-check-default.small .form-radio-input:checked + label:after {
  background-color: #00c1d5;
  left: 0;
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  top: 9px;
  left: 3px;
}
.form-check-default .form-radio-input:checked + label.middle:after {
  background-color: #00c1d5;
  left: 0;
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 4px;
}
.form-check-default
  .form-radio-input:disabled:checked
  + .form-radio-label:before {
  border: 1px solid #777;
  background-color: #eee;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 11px;
}
.form-check-default
  .form-radio-input:disabled:checked
  + .form-radio-label:after {
  background-color: #777;
  left: 0;
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: absolute;
  top: 6px;
  left: 4px;
}
.optionBuy input {
  position: absolute;
  left: -9999px;
}
.optionBuy input:focus {
  box-shadow: none;
}

.optionBuy label:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #00c1d5;
  position: absolute;
  display: block;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
}
.optionBuy input:not(:checked) + label:after {
  color: #000;
  opacity: 0;
}
.optionBuy input:checked + label {
  @apply relative bg-white shadow outline-2 outline-red-500;
}

.optionBuy input:checked + label .sale-price p {
  @apply text-primary-second;
}
.optionBuy input:disabled + label:after {
  content: '';
}
.optionBuy input:disabled + label:before {
  border: 1px solid #000;
}
.optionBuy input:disabled:checked + label {
  color: #777;
}
.optionBuy label {
  @apply rounded-lg outline outline-1 outline-zinc-500;
}

.optionBuyFlashSale input {
  position: absolute;
  left: -9999px;
}
.optionBuyFlashSale input:focus {
  box-shadow: none;
}
.optionBuyFlashSale input + label .discount {
  @apply bg-white;
}
.optionBuyFlashSale input + label .discount span {
  @apply text-dark-900;
}
.optionBuyFlashSale input:checked + label .textTop,
.optionBuyFlashSale input:checked + label .originPrice p {
  @apply !text-dark-400;
}
.optionBuyFlashSale label:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  position: absolute;
  display: block;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}
.optionBuyFlashSale input:not(:checked) + label:after {
  color: #000;
  opacity: 0;
}
.optionBuyFlashSale input:checked + label {
  @apply relative bg-white shadow outline-none;
}
.optionBuyFlashSale input:checked + label .sale-price p {
  @apply text-primary-second;
}
.optionBuyFlashSale input:checked + label .discount {
  @apply bg-primary-second;
}
.optionBuyFlashSale input:checked + label .discount span {
  @apply text-stone-50;
}
.optionBuyFlashSale input:disabled + label:after {
  content: '';
}
.optionBuyFlashSale input:disabled + label:before {
  border: 1px solid #000;
}
.optionBuyFlashSale input:disabled:checked + label {
  color: #777;
}
.optionBuyFlashSale label {
  @apply rounded-lg outline outline-2 outline-white/50;
}

.preOrderRadio .flag {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
  overflow: hidden;
  border-radius: 8px;
}
.preOrderRadio input:checked + label .flag {
  background-position: 102% -2px;
  background-repeat: no-repeat;
  background-size: 38px 39px;
  display: block;
}
.preOrderRadio input:checked + label .flag:before {
  content: '';
  background-position: top right;
  background-repeat: no-repeat;
  width: 12px;
  background-size: 100%;
  display: block;
  height: 12px;
  position: absolute;
  right: 3px;
  top: 5px;
}
.optionBuy input:checked + label .flag {
  background-image: url('/assets/icons/preorderLabel.svg');
}
.optionBuy input:checked + label .flag:before {
  background-image: url('/assets/icons/checkPre.svg');
}
.optionBuyFlashSale input:checked + label .flag {
  background-image: url('/assets/icons/preorderLabelFls.svg');
}
.optionBuyFlashSale input:checked + label .flag:before {
  background-image: url('/assets/icons/checkFls.svg');
}
div.form-check-border:has(> input.form-radio-input:checked) {
  border: 1px solid #00c1d5;
}
.homeSlider .slick-list {
}
.homeSlider .slick-slide {
  /* margin: 0 20%; */
}
.slickNextCS,
.slickPrevCS {
  @apply !absolute !top-[50%] !z-50 !flex !h-12 !w-12 translate-y-[-50%] items-center !rounded-full !bg-white;
}
.slickNextCS {
  @apply !-right-4;
}

.slickPrevCS {
  @apply !-left-4;
}
.slickNextCS.slick-disabled,
.slickPrevCS.slick-disabled {
  @apply !cursor-not-allowed opacity-30;
}
.rc-menu-submenu-placement-rightBottom,
.top-225 {
  position: fixed !important;
}
.rc-menu {
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
    drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  box-shadow: none !important;
}
.overflowX::-webkit-scrollbar {
  height: 8px;
  margin-top: 3px;
}
.voucherRight,
.voucherLeft {
  position: relative;
  display: flex;
  height: 100%;
  border: 1px solid rgba(255, 0, 0, 0.1);
  align-items: center;
  /* overflow: hidden; */
}
.voucherRight {
  border-left: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.voucherLeft {
  border-right: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.voucherRight::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 70%;
  border-left: 1px dashed #e4e4e4;
  display: block;
}
.voucherLeft::before {
  content: '';
  position: absolute;
  right: -6px;
  top: -7px;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid rgba(255, 0, 0, 0.1);
  display: block;
  z-index: 1;
  border-radius: 100%;
}
.voucherLeft::after {
  content: '';
  position: absolute;
  right: -6px;
  bottom: -7px;
  top: auto;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid rgba(255, 0, 0, 0.1);
  display: block;
  z-index: 1;
  border-radius: 100%;
}
.activeMenu svg {
  fill: #00c1d5;
}
.activeMenu svg * {
  fill: #00c1d5;
}
.react-datepicker__view-calendar-icon input {
  padding: 6px 25px 5px 12px;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}
.swpNav {
  @apply h-10 w-10;
}
.bundleArrow {
  margin: 0 -16px;
}
.swiper-button-disabled {
  opacity: 0.5;
}
.swiper-button-disabled {
  cursor: not-allowed;
}
.radioReviewOrder input {
  position: absolute;
  left: -9999px;
  visibility: hidden;
}
.radioReviewOrder .reviewOrderInput:focus {
  box-shadow: none;
}
.radioReviewOrder .reviewOrderInput:checked + label {
  @apply border-green-100 font-bold;
}
.radioReviewOrder .reviewOrderInput label {
  position: relative;
}
.gridFs {
  --gap: 2rem;
  --line-offset: calc(var(--gap) / 2);
  --line-thickness: 2px;
  --line-color: #f6f4f5;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  overflow: hidden;
  gap: var(--gap);
}

.grid__item {
  position: relative;
}

.grid__item::before,
.grid__item::after {
  content: '';
  position: absolute;
  background-color: var(--line-color);
  z-index: 1;
}

.grid__item::after {
  inline-size: 100vw;
  block-size: var(--line-thickness);
  inset-inline-start: 0;
  inset-block-start: calc(var(--line-offset) * -1);
}

.grid__item::before {
  inline-size: var(--line-thickness);
  block-size: 100vh;
  inset-inline-start: calc(var(--line-offset) * -1);
}
.yarl__navigation_prev {
  left: 40px !important;
}
.yarl__navigation_next {
  right: 40px !important;
}
.yarl__slide {
  padding: 0 !important;
}
.yarl__toolbar {
  background-color: #fff;
}

/* .review:hover svg path {
  stroke: #00c1d5;
} */
